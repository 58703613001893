import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { removeKeysFromObject } from '@/App/Utils/utils'
import { Validator } from '@/App/Mixins'
import { NotificationService } from '@/App/Services/Notification.service'
import SelectDashboard from '@/App/Components/DashboardSelect/Dashboard-Select.vue'
import WidgetSelect from '@/App/Components/WidgetSelect/Widget-Select.vue'
import DeviceSelect from '@/App/Components/DeviceSelect/Device-Select.vue'
import UserSelect from '@/App/Components/UserSelect/User-Select.vue'
import MessageTemplate from './MessageTemplate/Message-Template.vue'
import SensorSelect from '@/App/Components/SensorSelect/Sensor-Select.vue'
import SettingsSelect from '@/App/Components/SettingsSelect/Settings-Select.vue'
import GeofenceSelect from '@/App/Components/GeofenceSelect/Geofence-Select.vue'
import LocationDeviceSelect from '@/App/Components/LocationDeviceSelect/Location-Device-Select.vue'
import CalculatorSelect from '@/App/Components/CalculatorSelect/Calculator-Select.vue'
import { VuetifySelect } from '@/interfaces/vuetify'
import { Notification } from '@/App/Services/interfaces/notification.interface'
import { ConfirmDialogOptions, Notification as NotificationUI } from '@/store/interfaces/shared.interfaces'

const sharedStore = namespace('Shared')
const notificationStore = namespace('Notification')

@Component({
  components: {
    SelectDashboard,
    WidgetSelect,
    DeviceSelect,
    UserSelect,
    MessageTemplate,
    SensorSelect,
    SettingsSelect,
    GeofenceSelect,
    LocationDeviceSelect,
    CalculatorSelect
  }
})
export default class NotificationForm extends Mixins(Validator) {
  @sharedStore.Mutation setNotification: (notification: NotificationUI) => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @notificationStore.Mutation addNotification: (notification: Notification) => void
  @notificationStore.Mutation updateNotification: (notification: Notification) => void
  public step = 1
  public isLoading = false
  public form = false
  public notificationTypesItems: VuetifySelect[] = [
    { value: 'sensorValue', text: this.$t('notificationsPage.notificationForm.sensorValueText').toString() },
    { value: 'sensorDataUnavailable', text: this.$t('notificationsPage.notificationForm.sensorDataUnavailableText').toString() },
    { value: 'connectionLoss', text: this.$t('notificationsPage.notificationForm.connectionLossText').toString() },
    { value: 'parameter', text: this.$t('notificationsPage.notificationForm.parameterText').toString() },
    { value: 'geofence', text: this.$t('notificationsPage.notificationForm.geofenceText').toString() }
  ]
  public triggerWhenItems: VuetifySelect[] = [
    { value: 'outOfRange', text: this.$t('notificationsPage.notificationForm.outOfRangeText').toString() },
    { value: 'inRange', text: this.$t('notificationsPage.notificationForm.inRangeText').toString() }
  ]

  public geofenceTriggerItems: VuetifySelect[] = [
    { value: 'activated', text: this.$t('notificationsPage.notificationForm.inText').toString() },
    { value: 'deactivated', text: this.$t('notificationsPage.notificationForm.outText').toString() },
    { value: 'both', text: this.$t('notificationsPage.notificationForm.bothText').toString() }
  ]
  public unitsItems = [ 'Seconds', 'Minutes', 'Hours' ]
  public timeBetweenNotificationsItems: VuetifySelect[] = [
    { value: 6e4, text: this.$t('notificationsPage.notificationForm.1MinText').toString() },
    { value: 6e5, text: this.$t('notificationsPage.notificationForm.10MinText').toString() },
    { value: 18e5, text: this.$t('notificationsPage.notificationForm.30MinText').toString() },
    { value: 36e5, text: this.$t('notificationsPage.notificationForm.1HourText').toString() },
    { value: 216e5, text: this.$t('notificationsPage.notificationForm.6HourText').toString() },
    { value: 1296e5, text: this.$t('notificationsPage.notificationForm.12HourText').toString() }
  ]
  public notification: Notification = {
    _id: '',
    type: 'sensorValue',
    name: '',
    dashboardId: '',
    widgetId: '',
    deviceId: 0,
    geofencesIds: [],
    devicesIds: [],
    calculatorId: null,
    sensorName: '',
    valueFrom: 0,
    valueTo: 1,
    triggerWhen: 'outOfRange',
    geofenceTrigger: 'both',
    duration: 0,
    units: 'Seconds',
    timeBetweenNotifications: 6e4,
    timeInterval: 1,
    isAppNotification: false,
    isEmailNotification: false,
    isSMSNotification: false,
    isTelegramNotification: false,
    emails: [],
    usersIds: [],
    phones: [],
    telegramChatIds: [],
    messageTemplate: '',
    isTimeControl: false,
    timeControlInterval: [
      { from: '', to: '' },
      { from: '', to: '' }
    ],
    isDayControl: false,
    daysOfWeek: [],
    isControlDevice: false,
    controlDeviceId: undefined,
    controlDeviceSettingsName: '',
    controlDevicePayload: '',
    isEnable: true
  }

  async onClose(type: 'create' | 'update') {
    const title = type === 'create' ? 'Finish creating notification?' : 'Finish updating notification?'
    const text = `
        ${this.$t('notificationsPage.notificationForm.confirmText')}<br/>
        ${type === 'create' ? 'This notification will not saved' : ' All changes will not saved'}.
    `

    if (await this.confirm({ title, text })) {
      this.$emit('onClose')
    }
  }

  async onSave() {
    try {
      this.isLoading = true

      const notification = await NotificationService.store(this.prepareNotificationData())

      this.setNotification({ text: this.$t('notificationsPage.notificationForm.thisNotificationNotSavedText').toString() })

      this.addNotification(notification)

      this.$emit('onClose')
    } catch {} finally {
      this.isLoading = false
    }
  }

  onEdit(notification: Notification) {
    if (!notification.timeControlInterval?.length) {
      notification.timeControlInterval = [
        { from: '', to: '' },
        { from: '', to: '' }
      ]
    }

    this.notification = { ...this.notification, ...notification }
  }

  async onUpdate() {
    try {
      this.isLoading = true

      const notification = await NotificationService.update(this.notification._id, this.prepareNotificationData())

      this.updateNotification(notification)

      this.setNotification({ text: this.$t('notificationsPage.notificationForm.thisNotificationNotSavedText').toString() })


      this.$emit('onClose')
    } catch {} finally {
      this.isLoading = false
    }
  }
  /**
   * Prepare data for action on server, deleted unused fields for selected notification type
   */
  private prepareNotificationData(): Notification {
    let notification: Notification = { ...this.notification }
    switch (this.notification.type) {
      case 'sensorValue':
        notification = removeKeysFromObject([
          'deviceId',
          'timeInterval'
        ], notification)
        break
      case 'sensorDataUnavailable':
        notification = removeKeysFromObject([
          'deviceId',
          'valueFrom',
          'valueTo',
          'triggerWhen',
          'duration',
          'units'
        ], notification)
        break
      case 'connectionLoss':
        notification = removeKeysFromObject([
          'dashboardId',
          'widgetId',
          'valueFrom',
          'valueTo',
          'triggerWhen',
          'duration',
          'units'
        ], notification)
        break
      case 'parameter':
        notification = removeKeysFromObject([
          'dashboardId',
          'widgetId',
          'timeInterval'
        ], notification)
        break
    }

    if (!notification.isTimeControl) {
      delete notification.timeControlInterval
    }

    if (!notification.isDayControl) {
      delete notification.daysOfWeek
    }

    if (!notification.isControlDevice) {
      delete notification.controlDeviceId
      delete notification.controlDeviceSettingsName
      delete notification.controlDevicePayload
    }
    //TODO unused field in notification object need to remove or add field for this
    delete notification.isEnable
    delete notification._id

    return notification
  }
}
