import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Validator } from '@/App/Mixins'
//TODO send as props type of notification for remove unused tags in template
@Component
export default class NotificationForm extends Mixins(Validator) {
  @Prop({ default: '' }) readonly value: string
  public messageTemplate = ''
  public messageTemplateTags: TemplateTags[] = [
    { name: '%UNIT%', description: this.$t('notificationsPage.notificationForm.messageTemplate.deviceNameText').toString() },
    { name: '%SENSOR_NAME%', description: this.$t('notificationsPage.notificationForm.messageTemplate.sensorNameText').toString() },
    { name: '%WIDGET_NAME%', description: this.$t('notificationsPage.notificationForm.messageTemplate.widgetNameText').toString() },
    { name: '%SENSOR_VALUE%', description: this.$t('notificationsPage.notificationForm.messageTemplate.valueOfSensorText').toString() },
    { name: '%CURR_TIME%', description: this.$t('notificationsPage.notificationForm.messageTemplate.timeActionText').toString() },
    { name: '%GEOFENCE_NAME%', description: this.$t('notificationsPage.notificationForm.messageTemplate.geofenceNameText').toString() },
    { name: '%GEOFENCE_STARTED%', description: this.$t('notificationsPage.notificationForm.messageTemplate.enterInTimeGeofenceText').toString() },
    { name: '%GEOFENCE_FINISHED%', description: this.$t('notificationsPage.notificationForm.messageTemplate.outGeofenceTimeText').toString() },
    { name: '%GEOFENCE_EVENT_TYPE%', description: this.$t('notificationsPage.notificationForm.messageTemplate.outOrInText').toString() }
  ]

  public onTagClick(tag: string) {
    this.messageTemplate = `${this.messageTemplate.trim()} ${tag}`.trim()

    this.$emit('input', this.messageTemplate)
  }

  @Watch('value')
  valueChanged(val: string) {
    this.messageTemplate = val
  }
}

interface TemplateTags {
  name: string
  description:string
}
