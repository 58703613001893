import { HTTP } from '@/App/Utils/axios'
import { Notification } from '@/App/Services/interfaces/notification.interface'
import { NotificationUpdate } from '@/store/interfaces/notification.interface'

export class NotificationService {
  static async fetch(): Promise<Notification[]> {
    try {
      const { data } = await HTTP.get('/notifications/')

      return data
    } catch (e) {
      throw e
    }
  }

  static async store(notification: Notification): Promise<Notification> {
    try {
      const { data } = await HTTP.post('/notifications/', notification)

      return data
    } catch (e) {
      throw e
    }
  }

  static async update(id: string, notification: NotificationUpdate): Promise<Notification> {
    try {
      const { data } = await HTTP.put(`/notifications/${id}`, notification)

      return data
    } catch (e) {
      throw e
    }
  }

  static async destroy(id: string): Promise<string> {
    try {
      const { data } = await HTTP.delete(`/notifications/${id}`)

      return data
    } catch (e) {
      throw e
    }
  }
}
