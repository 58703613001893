import { Component, Vue } from 'vue-property-decorator'
import NotificationsList from './NotificationsList/Notifications-List.vue'
import NotificationForm from './NotificationForm/Notification-Form.vue'

@Component({
  components: { NotificationsList, NotificationForm }
})
export default class NotificationsPage extends Vue {
  public isFormShow = false
}
