import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { NotificationService } from '@/App/Services/Notification.service'
import { Notification } from '@/App/Services/interfaces/notification.interface'
import { ConfirmDialogOptions, Notification as NotificationUI } from '@/store/interfaces/shared.interfaces'
import { NotificationUpdate } from '@/store/interfaces/notification.interface'

const notificationStore = namespace('Notification')
const sharedStore = namespace('Shared')

@Component
export default class NotificationsList extends Vue {
  @sharedStore.Mutation setNotification: (notification: NotificationUI) => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @notificationStore.Mutation setNotifications: (notifications: Notification[]) => void
  @notificationStore.Mutation deleteNotification: (id: string) => void
  @notificationStore.Mutation updateNotification: (notifications: NotificationUpdate) => void
  @notificationStore.State notifications: Notification[]

  public isLoading = false
  public search = ''

  public async onDelete(id: string) {
    try {
      const title = this.$t('notificationsPage.notificationList.deleteText').toString()

      if (await this.confirm({ title })) {
        this.isLoading = true

        await NotificationService.destroy(id)

        this.deleteNotification(id)

        this.setNotification({ text: this.$t('notificationsPage.notificationList.deletedNotificationText').toString() })
      }
    } catch {} finally {
      this.isLoading = false
    }
  }

  public async onStatusChange(id: string, status: boolean) {
    try {
      this.isLoading = true

      await NotificationService.update(id, { isEnable: status })

      this.updateNotification({ _id: id, isEnable: status })

      this.setNotification({ text: this.$t('notificationsPage.notificationList.statusChangedNotificationText').toString() })
    } catch {} finally {
      this.isLoading = false
    }
  }

  public onEdit(notification: Notification) {
    this.$emit('onCreate')
    // TODO maybe replace v-if on notification for on v-show
    // Now use this for waite until notification form will render after v-if status changed
    setTimeout(() => this.$emit('onEdit', notification), 200)
  }

  private async mounted() {
    try {
      this.isLoading = true

      const notifications = await NotificationService.fetch()

      this.setNotifications(notifications)
    } catch {} finally {
      this.isLoading = false
    }
  }
}
