import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { FlespiService } from '@/App/Services/Flespi.service'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { ValidationResult } from '@/types'

const sharedStore = namespace('Shared')

@Component
export default class SettingsSelect extends Mixins(Validator) {
  @Prop({ default: '' })
  private readonly value: string

  @Prop()
  public readonly deviceId!: string

  @Prop({ default: false })
  public readonly required!: boolean

  @Prop({ default: false })
  public dark: boolean

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public settingsName = ''
  public settingsItems: string[] = []
  public isLoading = false

  private async fillSettingsItems(): Promise<void> {
    try {
      if (this.deviceId) {
        this.isLoading = true

        this.settingsItems = []

        this.settingsItems = await FlespiService.fetchDeviceSettings(this.deviceId)
        this.settingsName = this.value
      }
    } catch {} finally {
      this.isLoading = false
    }
  }

  public validation(): ValidationResult {
    return this.required ? this.validator.isEmpty(this.settingsName) : true
  }

  public onSettingSelected(): void {
    this.$emit('input', this.settingsName)
  }

  @Watch('deviceId')
  private deviceIdChanged(): void {
    this.fillSettingsItems()
  }

  @Watch('value')
  private valueChanged(val: string): void {
    this.settingsName = val
  }

  @Watch('settingsItems')
  private settingsItemsChanged(): void {
    this.settingsName = this.value
  }

  private mounted(): void {
    this.fillSettingsItems()
  }
}
